<template>
  <div class="landing-page-container">
    <div class="landing-page-top-container">
      <div class="landing-page-hero">
        <div class="landing-page-content-container">
          <h1 class="landing-page-text Heading">Keski-Suomessa</h1>
          <h2 class="landing-page-subheading Subheading">
            <span>
              ilmakuvaukset ja
              <span v-html="rawa6bs"></span>
            </span>
            <span>
              3D-mallinnukset
              <span v-html="raw3t8l"></span>
            </span>
          </h2>
        </div>
      </div>
    </div>
    <div id="main-section" class="landing-page-main">
      <div class="landing-page-cards-container">
        <place-card2
          city="Virtuaaliesittely"
          text="Katso esimerkki tästä"
          image="/virtuaali-300h.png"
          linkText="https://my.matterport.com/show?m=Gv4Qu4gwWUK"
          description="Erinomainen tapa esitellä asunto on virtuaalinen kierros. 360-kameralla kuvatulla kierroksella voi liikkua ympäri asuntoa tai tonttia."
          rootClassName="place-card2-root-class-name"
        ></place-card2>
        <place-card
          city="Ilmakuvaus"
          image="/dji_0389-300h.jpg"
          description="Laadukkaat kuvat ja videot moniin eri käyttötarkoituksiin. Myös tarkastuslennot. Nopealla aikataululla, jos sää on sopiva."
          rootClassName="place-card-root-class-name"
        ></place-card>
        <place-card
          city="Mainokset"
          image="https://images.unsplash.com/photo-1543140313-318677635120?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDZ8fGRyb25lJTIwYm9hdHxlbnwwfHx8fDE2NTY1ODA5OTY&amp;ixlib=rb-1.2.1&amp;w=300"
          description="Esittele näyttävästi parhaasta kuvakulmasta. Saat täyden palvelun paketin. Suunnittelu, kuvaus, editointi ja myös julkaisu tarvittaessa."
          rootClassName="place-card-root-class-name1"
        ></place-card>
      </div>
      <h1 class="landing-page-text03">
        tilaa@kauppinen.kim
        <span v-html="rawnydo"></span>
      </h1>
    </div>
    <div class="landing-page-pricing">
      <div class="landing-page-container01">
        <div class="landing-page-pricing-card">
          <span class="landing-page-text04">
            Virtuaaliesittely
            <span v-html="raw8gka"></span>
          </span>
          <div class="landing-page-container02">
            <div class="landing-page-container03">
              <span class="landing-page-text05">
                <span>€</span>
                <span></span>
              </span>
              <span class="landing-page-text08">149</span>
              <span class="landing-page-text09">/ alkaen</span>
            </div>
          </div>
          <div class="landing-page-container04">
            <span class="landing-page-text10">
              <span>
                hinta sis. alv 24%
                <span v-html="rawsf8x"></span>
              </span>
              <br />
            </span>
            <span class="landing-page-text13">
              <span>✔ 360° kuvaus / Matterport</span>
              <br />
              <br />
              <span>
                ✔ Toimii tietokoneella ja puhelimella. Helposti jaettava linkki.
              </span>
            </span>
            <span class="landing-page-text18">
              <span>
                ✔ 3,50€ / kk
                <span v-html="raw1gzh"></span>
              </span>
              <br />
              <span>(linkin/esittelyn ylläpito)  </span>
            </span>
          </div>
        </div>
        <div class="landing-page-pricing-card1">
          <span class="landing-page-text22">ILMAKUVAUS</span>
          <div class="landing-page-container05">
            <span class="landing-page-text23">
              <span>€</span>
              <span></span>
            </span>
            <span class="landing-page-text26">125</span>
            <span class="landing-page-text27">/ alkaen</span>
          </div>
          <span class="landing-page-text28">
            <span>
              hinta sis. alv 24%
              <span v-html="rawkg8b"></span>
            </span>
            <br />
          </span>
          <div class="landing-page-container06">
            <span class="landing-page-text31">
              <span>
                ✔ Asunnosta, mökistä, kiinteistöstä, veneestä, tontista ...
              </span>
            </span>
            <span class="landing-page-text33">
              <span>✔ Laadukkaat kuvat ja 4K videot</span>
            </span>
            <span class="landing-page-text35">
              <span>✔ Editointi lisäpalveluna</span>
            </span>
            <span class="landing-page-text37">
              <span>✔ Toimitus haluamallasi tavalla</span>
            </span>
          </div>
        </div>
        <div class="landing-page-pricing-card2">
          <span class="landing-page-text39">
            <span class="landing-page-text40">Mainokset</span>
          </span>
          <div class="landing-page-container07">
            <span class="landing-page-text41">
              <span>€</span>
              <span></span>
            </span>
            <span class="landing-page-text44"> 499</span>
            <span class="landing-page-text45"><span>/ alkaen</span></span>
          </div>
          <span class="landing-page-text47">
            <span>hinta alv 0%</span>
            <span></span>
          </span>
          <div class="landing-page-container08">
            <span class="landing-page-text50">
              <span>
                ✔ Valmiina julkaistavaksi esim. someen tai nettisivuille.
                <span v-html="rawje05"></span>
              </span>
              <span><span v-html="raweueg"></span></span>
            </span>
            <span class="landing-page-text53">
              <span>✔ Näyttäviä ilmakuvia</span>
            </span>
            <span class="landing-page-text55">
              <span>✔ Katseen nappaavia videoita</span>
            </span>
            <span class="landing-page-text57">
              <span>✔ Mainokset ja esittelyvideot</span>
            </span>
            <span class="landing-page-text59"><span>✔ Esitteet</span></span>
          </div>
        </div>
      </div>
    </div>
    <span class="landing-page-text61">
      <span>Arvioi ilmakuvauksen hinta</span>
      <br />
    </span>
    <div class="landing-page-container09">
      <div class="landing-page-container10">
        <DangerousHTML
          html="<style>
  body {
    padding-top: 100px;
    font-family: Arial, sans-serif;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 2000px;
    margin: auto;
    padding: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  
  .slider {
    width: 80%;
    height: 30px;
    border-radius: 15px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .price {
    width: 20%;
    text-align: right;
    font-size: 64px;
    font-weight: bold;
  }
  
  .minutes, .price-label, .distance {
    font-size: 24px;
    font-weight: bold;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .main-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .selections {
    width: 70%;
  }
  
  /* Styles for screens that are 600px or less */
  @media screen and (max-width: 600px) {
    .slider {
      width: 70%;
      height: 10px;
    }
  
    .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
    }
  
    .price {
      font-size: 32px;
    }
  
    .minutes, .price-label, .distance, .title, .main-title {
      font-size: 16px;
    }
  }
  </style>
  
  <div class='container'>
    <div class='selections'>
      <p class='title'>Kuvauksen kesto</p>
      <p class='minutes' id='minutes'>20 min</p>
      <input type='range' min='20' max='60' value='20' class='slider' id='myRange' step='20'>
      <br><br><br>
      <p class='title'>Kilometriä Jyväskylän keskustasta</p>
      <p class='distance' id='distanceLabel'>0 km</p>
      <input type='range' min='0' max='60' value='0' class='slider' id='distance' step='1'>
    </div>
    <div>
      <p class='price-label'>Hinta</p>
      <p class='price' id='price'>125€</p>
    </div>
  </div>
  
  <script>
  var slider = document.getElementById('myRange');
  var price = document.getElementById('price');
  var minutes = document.getElementById('minutes');
  var distance = document.getElementById('distance');
  var distanceLabel = document.getElementById('distanceLabel');
  
  function updatePrice() {
    var basePrice;
    if(slider.value == 20) {
      basePrice = 125;
    } else if(slider.value == 40) {
      basePrice = 149;
    } else if(slider.value == 60) {
      basePrice = 179;
    }
  
    basePrice += distance.value * 0.90;
  
    price.innerHTML = basePrice.toFixed(2) + '€';
  }
  
  slider.oninput = function() {
    minutes.innerHTML = this.value + ' min';
    updatePrice();
  }
  
  distance.oninput = function() {
    distanceLabel.innerHTML = this.value + ' km';
    updatePrice();
  }
  </script>
  "
        ></DangerousHTML>
      </div>
    </div>
    <div class="landing-page-gallery">
      <gallery-card1
        imageSrc="https://rstr.in/2tbovts8mkjsl0/my-library/Tza8OWSh_Ky"
        rootClassName="rootClassName"
      ></gallery-card1>
      <gallery-card1
        imageSrc="https://rstr.in/2tbovts8mkjsl0/my-library/2Jpojc1rhuI"
        rootClassName="rootClassName1"
      ></gallery-card1>
      <gallery-card1
        imageSrc="https://rstr.in/2tbovts8mkjsl0/my-library/H6_Kfs4ODoq"
        rootClassName="rootClassName4"
      ></gallery-card1>
      <gallery-card1
        imageSrc="https://rstr.in/2tbovts8mkjsl0/my-library/QJGcBJB7tqH"
        rootClassName="rootClassName2"
      ></gallery-card1>
    </div>
    <div class="landing-page-container11">
      <div class="landing-page-container12">
        <DangerousHTML
          html="<iframe src='https://docs.google.com/forms/d/e/1FAIpQLSeXjqLDb_tPGU2lBxaiRzSTEbYwU3qC86tuCNBEL3F2hSUa4A/viewform?embedded=true' width='640' height='1130' frameborder='0' marginheight='0' marginwidth='0'>Ladataan…</iframe>"
        ></DangerousHTML>
      </div>
    </div>
    <div class="landing-page-features">
      <h1 class="landing-page-text64">UKK / Q&amp;A</h1>
      <div class="landing-page-container13">
        <feature-card2
          title="Lopullinen hinta"
          newProp="Lopullinen hinta"
          description="Pyytämällä tarjouksen saat hinnan joka pitää. Ilmakuvauksia varten kerro viestissä osoite ja kohteen tyyppi(kiinteistö, tapahtuma, tontti, vene). "
          rootClassName="rootClassName"
        ></feature-card2>
        <feature-card2
          title="Materiaalin toimitus"
          newProp="Materiaalin toimitus"
          description="Asiakas saa linkin sähköpostiin josta voi katsoa ja ladata kuvat/videot. Tarvittaessa myös muut tavat ovat sovittavissa."
          rootClassName="rootClassName3"
        ></feature-card2>
        <feature-card2
          title="Nettisivut yritykselle"
          newProp="Nettisivut yritykselle"
          description="Ilmakuvia ja videoita hyödyntämällä saadaan nopeasti hienot nettisivut yritykselle. Ota yhteyttä ja kysy lisää."
          rootClassName="rootClassName2"
        ></feature-card2>
        <feature-card2
          title="Rajoitukset ja luvat kuvatessa"
          newProp="Rajoitukset ja luvat kuvatessa"
          description="Voimassa olevia lakeja kuvaamiseen ja dronella lentämiseen noudatetaan. UAS-ilmatilavyöhykkeet ja muut ilmailun kielto-, rajoitus- ja vaara-alueet huomioidaan. Käytössä olevalla dronella saa lentää yksittäisten ihmisten yli. Lennot onnistuvat myös kaupungissa."
          rootClassName="rootClassName1"
        ></feature-card2>
      </div>
    </div>
    <div class="landing-page-footer">
      <div class="landing-page-menu">
        <img
          alt="image"
          src="/tdb_jao2_400x400-200h.jpg"
          loading="lazy"
          class="landing-page-image"
        />
        <h1 class="landing-page-logo">tilaa@kauppinen.kim</h1>
        <span class="landing-page-text65">Sivusto käyttää evästeitä</span>
        <div class="landing-page-follow-container">
          <span class="landing-page-text66">Seuraa</span>
          <div class="landing-page-icons-container">
            <a
              href="https://www.youtube.com/channel/UCsNrirLq0SztGfZs2AHczug"
              target="_blank"
              rel="noreferrer noopener"
              class="landing-page-link"
            >
              <svg viewBox="0 0 1024 1024" class="landing-page-icon">
                <path
                  d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/kauppinen.kim/"
              target="_blank"
              rel="noreferrer noopener"
              class="landing-page-link1"
            >
              <svg viewBox="0 0 1024 1024" class="landing-page-icon2">
                <path
                  d="M512 92.2c136.8 0 153 0.6 206.8 3 50 2.2 77 10.6 95 17.6 23.8 9.2 41 20.4 58.8 38.2 18 18 29 35 38.4 58.8 7 18 15.4 45.2 17.6 95 2.4 54 3 70.2 3 206.8s-0.6 153-3 206.8c-2.2 50-10.6 77-17.6 95-9.2 23.8-20.4 41-38.2 58.8-18 18-35 29-58.8 38.4-18 7-45.2 15.4-95 17.6-54 2.4-70.2 3-206.8 3s-153-0.6-206.8-3c-50-2.2-77-10.6-95-17.6-23.8-9.2-41-20.4-58.8-38.2-18-18-29-35-38.4-58.8-7-18-15.4-45.2-17.6-95-2.4-54-3-70.2-3-206.8s0.6-153 3-206.8c2.2-50 10.6-77 17.6-95 9.2-23.8 20.4-41 38.2-58.8 18-18 35-29 58.8-38.4 18-7 45.2-15.4 95-17.6 53.8-2.4 70-3 206.8-3zM512 0c-139 0-156.4 0.6-211 3-54.4 2.4-91.8 11.2-124.2 23.8-33.8 13.2-62.4 30.6-90.8 59.2-28.6 28.4-46 57-59.2 90.6-12.6 32.6-21.4 69.8-23.8 124.2-2.4 54.8-3 72.2-3 211.2s0.6 156.4 3 211c2.4 54.4 11.2 91.8 23.8 124.2 13.2 33.8 30.6 62.4 59.2 90.8 28.4 28.4 57 46 90.6 59 32.6 12.6 69.8 21.4 124.2 23.8 54.6 2.4 72 3 211 3s156.4-0.6 211-3c54.4-2.4 91.8-11.2 124.2-23.8 33.6-13 62.2-30.6 90.6-59s46-57 59-90.6c12.6-32.6 21.4-69.8 23.8-124.2 2.4-54.6 3-72 3-211s-0.6-156.4-3-211c-2.4-54.4-11.2-91.8-23.8-124.2-12.6-34-30-62.6-58.6-91-28.4-28.4-57-46-90.6-59-32.6-12.6-69.8-21.4-124.2-23.8-54.8-2.6-72.2-3.2-211.2-3.2v0z"
                ></path>
                <path
                  d="M512 249c-145.2 0-263 117.8-263 263s117.8 263 263 263 263-117.8 263-263c0-145.2-117.8-263-263-263zM512 682.6c-94.2 0-170.6-76.4-170.6-170.6s76.4-170.6 170.6-170.6c94.2 0 170.6 76.4 170.6 170.6s-76.4 170.6-170.6 170.6z"
                ></path>
                <path
                  d="M846.8 238.6c0 33.91-27.49 61.4-61.4 61.4s-61.4-27.49-61.4-61.4c0-33.91 27.49-61.4 61.4-61.4s61.4 27.49 61.4 61.4z"
                ></path>
              </svg>
            </a>
            <a
              href="https://fb.me/kauppinen.kim"
              target="_blank"
              rel="noreferrer noopener"
              class="landing-page-link2"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                class="landing-page-icon6"
              >
                <path
                  d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import PlaceCard2 from '../components/place-card2'
import PlaceCard from '../components/place-card'
import GalleryCard1 from '../components/gallery-card1'
import FeatureCard2 from '../components/feature-card2'

export default {
  name: 'LandingPage',
  props: {},
  components: {
    PlaceCard2,
    PlaceCard,
    DangerousHTML,
    GalleryCard1,
    FeatureCard2,
  },
  data() {
    return {
      rawa6bs: ' ',
      raw3t8l: ' ',
      rawnydo: ' ',
      raw8gka: ' ',
      rawsf8x: ' ',
      raw1gzh: ' ',
      rawkg8b: ' ',
      rawje05: ' ',
      raweueg: ' ',
    }
  },
  metaInfo: {
    title: 'Kauppinen.kim',
    meta: [
      {
        name: 'description',
        content:
          'Jyväskylässä ilmakuvaukset ja 3D-mallinnukset. Kim Kauppinen ',
      },
      {
        property: 'og:title',
        content: 'Kauppinen.kim',
      },
      {
        property: 'og:description',
        content:
          'Laadukkaat ilmakuvat ja videot Jyväskylässä. Myös yritysten esittelyvideot! Ota yhteyttä!',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2456ab3a-bed8-4da2-98c6-e0d4b1b4553e/c97b832f-2f7d-45dc-93ca-836f4de318b2?org_if_sml=1&q=80&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.landing-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-page-top-container {
  width: 100%;
  height: 507px;
  display: flex;
  margin-top: -100px;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 17.00%,rgba(0, 0, 0, 0.3) 89.00%),url("/kim-kauppinen-arzufp9nln0-unsplash%20%5B1%5D-1500w.jpg");
  background-position: 0% 40%;
}
.landing-page-hero {
  width: 100%;
  height: 379px;
  display: flex;
  max-width: 1000px;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: 131px;
  justify-content: space-between;
}
.landing-page-content-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-text {
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  background-color: #000000;
}
.landing-page-subheading {
  color: #ffffff;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: rgba(0, 0, 0, 0.41);
}
.landing-page-main {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 26px;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: 40px;
  justify-content: flex-start;
}
.landing-page-cards-container {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-text03 {
  text-align: center;
}
.landing-page-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1523346679608-dbbfe6c549d4?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ3fHxkcm9uZXxlbnwwfHx8fDE2NTQ3OTQ2MzI&ixlib=rb-1.2.1&w=1500");
  background-position: center;
}
.landing-page-container01 {
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.landing-page-pricing-card {
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 48px;
  margin-right: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.landing-page-pricing-card:hover {
  transform: scale(1.02);
}
.landing-page-text04 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.landing-page-container02 {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: row;
}
.landing-page-container03 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
}
.landing-page-text05 {
  font-size: 1.15rem;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-text08 {
  font-size: 4rem;
  font-weight: 700;
}
.landing-page-text09 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-container04 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 48px;
  flex-direction: column;
}
.landing-page-text10 {
  color: var(--dl-color-gray-500);
  font-size: 0.85rem;
  margin-top: -40px;
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text18 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-pricing-card1 {
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 32px;
  margin-right: 32px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.landing-page-pricing-card1:hover {
  transform: scale(1.02);
}
.landing-page-text22 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.landing-page-container05 {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: row;
}
.landing-page-text23 {
  font-size: 1.15rem;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-text26 {
  font-size: 4rem;
  font-weight: 700;
}
.landing-page-text27 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-text28 {
  color: var(--dl-color-gray-500);
  font-size: 0.85rem;
  margin-bottom: 32px;
}
.landing-page-container06 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 48px;
  flex-direction: column;
}
.landing-page-text31 {
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text33 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text35 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text37 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-pricing-card2 {
  width: 100%;
  display: flex;
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.landing-page-pricing-card2:hover {
  transform: scale(1.02);
}
.landing-page-text39 {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.landing-page-text40 {
  font-weight: 600;
  text-transform: uppercase;
}
.landing-page-container07 {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-direction: row;
}
.landing-page-text41 {
  font-size: 1.15rem;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-text44 {
  font-size: 4rem;
  font-weight: 700;
}
.landing-page-text45 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: 32px;
  font-weight: 300;
}
.landing-page-text47 {
  color: var(--dl-color-gray-500);
  font-size: 0.95rem;
  margin-bottom: 32px;
}
.landing-page-container08 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.landing-page-text50 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text53 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text55 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text57 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text59 {
  margin-bottom: var(--dl-space-space-unit);
}
.landing-page-text61 {
  font-size: 28px;
  align-self: center;
  font-style: normal;
  margin-top: 50px;
  font-weight: 800;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: -150px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-tripleunit);
}
.landing-page-container09 {
  width: 100%;
  height: 431px;
  border-color: rgba(0, 0, 0, 0);
  border-width: 0px;
}
.landing-page-container10 {
  display: contents;
}
.landing-page-gallery {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: var(--dl-space-space-unit);
  position: relative;
  max-width: 1400px;
  grid-template-columns: 1fr 1fr;
}
.landing-page-container11 {
  width: 618px;
  display: none;
  margin-bottom: 136px;
}
.landing-page-container12 {
  display: contents;
}
.landing-page-features {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  flex-direction: column;
}
.landing-page-text64 {
  font-size: 3rem;
  margin-bottom: 48px;
}
.landing-page-container13 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;
}
.landing-page-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 236px;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  padding-bottom: 146px;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.landing-page-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-image {
  width: 125px;
  height: 125px;
  object-fit: cover;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-logo {
  padding-left: var(--dl-space-space-unit);
}
.landing-page-text65 {
  text-align: center;
}
.landing-page-follow-container {
  display: none;
  position: relative;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text66 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link {
  display: contents;
}
.landing-page-icon {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.landing-page-link1 {
  display: contents;
}
.landing-page-icon2 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.landing-page-link2 {
  display: contents;
}
.landing-page-icon6 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .landing-page-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .landing-page-cards-container {
    justify-content: center;
  }
  .landing-page-text03 {
    width: 645px;
    text-align: center;
  }
  .landing-page-container01 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    align-self: center;
  }
  .landing-page-pricing-card {
    width: 100%;
    padding: 32px;
    max-width: 100%;
    align-items: center;
  }
  .landing-page-pricing-card1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .landing-page-pricing-card2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .landing-page-text61 {
    align-self: center;
  }
  .landing-page-gallery {
    grid-template-columns: 1fr 1fr;
  }
  .landing-page-container11 {
    width: 617px;
  }
  .landing-page-logo {
    color: #ffffff;
  }
  .landing-page-text65 {
    padding-left: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .landing-page-hero {
    justify-content: center;
  }
  .landing-page-content-container {
    align-items: center;
  }
  .landing-page-text {
    font-size: 2.5rem;
  }
  .landing-page-subheading {
    width: 443px;
    align-self: center;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .landing-page-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .landing-page-container01 {
    flex-flow: column;
    padding-left: 32px;
    padding-right: 32px;
  }
  .landing-page-pricing-card {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 32px;
  }
  .landing-page-pricing-card1 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 32px;
  }
  .landing-page-pricing-card2 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 32px;
  }
  .landing-page-text61 {
    align-self: center;
  }
  .landing-page-container09 {
    height: 558px;
  }
  .landing-page-gallery {
    padding-left: 32px;
    padding-right: 32px;
  }
  .landing-page-container11 {
    width: 100%;
  }
  .landing-page-features {
    padding-left: 32px;
    padding-right: 32px;
  }
  .landing-page-container13 {
    grid-template-columns: 1fr;
  }
  .landing-page-footer {
    height: 501px;
  }
  .landing-page-menu {
    align-items: center;
    flex-direction: column;
  }
  .landing-page-follow-container {
    align-self: center;
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .landing-page-top-container {
    align-items: center;
    background-image: url("/kim-kauppinen-arzufp9nln0-unsplash%20%5B1%5D-1500w.jpg");
    background-position: 0% 40%,center;
  }
  .landing-page-text {
    font-size: 2rem;
    text-align: center;
  }
  .landing-page-subheading {
    width: 258px;
    height: 65px;
    font-size: 1.3rem;
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .landing-page-text03 {
    width: 416px;
    text-align: center;
  }
  .landing-page-container01 {
    width: auto;
    max-width: auto;
    min-width: auto;
    margin-left: var(--dl-space-space-halfunit);
    padding-top: 32px;
    margin-right: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .landing-page-pricing-card2 {
    margin-bottom: 0px;
  }
  .landing-page-text61 {
    align-self: center;
  }
  .landing-page-container09 {
    height: 416px;
  }
  .landing-page-gallery {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .landing-page-container11 {
    width: 435px;
  }
  .landing-page-features {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .landing-page-footer {
    padding-top: var(--dl-space-space-tripleunit);
    padding-left: 0px;
    padding-right: 5px;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .landing-page-logo {
    width: 279px;
    height: 47px;
    font-size: 1.5em;
    align-self: center;
    text-align: center;
    font-weight: 700;
  }
  .landing-page-follow-container {
    margin-left: 0px;
  }
}
</style>
