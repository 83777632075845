<template>
  <div class="feature-card2-feature-card" v-bind:class="rootClassName">
    <div class="feature-card2-container">
      <h2 class="feature-card2-text">{{ newProp }}</h2>
      <span>{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard2',
  props: {
    newProp: {
      type: String,
      default: 'Lorem ipsum',
    },
    rootClassName: String,
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
    },
  },
}
</script>

<style scoped>
.feature-card2-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.feature-card2-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card2-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}




@media(max-width: 767px) {
  .feature-card2-feature-card {
    flex-direction: row;
  }
  .feature-card2-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card2-container {
    flex-direction: column;
  }
}
</style>
