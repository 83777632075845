<template>
  <div class="place-card-container" v-bind:class="rootClassName">
    <img :alt="imageAlt" :src="image" class="place-card-image" />
    <div class="place-card-container1">
      <span class="place-card-text">{{ city }}</span>
      <span class="place-card-text1">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceCard',
  props: {
    image: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1514924013411-cbf25faa35bb?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1000',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
    city: {
      type: String,
      default: 'City Name',
    },
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.place-card-container {
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.place-card-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
}
.place-card-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
}
.place-card-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.place-card-text1 {
  font-size: 13px;
  max-width: 325px;
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.place-card-root-class-name {
  align-self: flex-start;
}
.place-card-root-class-name1 {
  width: 300px;
}
@media(max-width: 767px) {
  .place-card-container {
    width: 200px;
  }
}
@media(max-width: 479px) {
  .place-card-container {
    width: 300px;
  }
}
</style>
